import BaseControl from '../base-control/base-control'
import LayoutList from '../templates/list-control.tpl'
import LayoutListSwiper from '../templates/list-control-swiper.tpl'
import LayoutListSwipe from '../templates/list-control-swipe.tpl'
import each from 'lodash/each'

import ImageProxyHelper from 'core/helpers/image-proxy-helper'

class ListControl extends BaseControl {
  constructor (controller, view) {
    let Layout = LayoutList
    switch (view) {
      case 'swiper':
        Layout = LayoutListSwiper
        break
      case 'gallery':
        Layout = LayoutListSwipe
        break
    }
    super(controller, () => Layout, controller.model, 'list')
    this.view = view
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    if (!this.controller.editorContext) {
      const galleryElements = element.querySelectorAll('.kv-gallery')
      if (galleryElements.length > 0) {
        this.galleryImages = []
        each(galleryElements, (galleryElement, index) => {
          const data = this.controller.model[index]
          let src
          // Get data from the element when there's no list model
          if (!data && galleryElement.tagName === 'DIV') {
            // Get everything between 'url( and )
            src = galleryElement.style.backgroundImage.match(/url\((.*?)\)/) || ''
            if (src.length > 0) {
              src = src[1].replace(/('|")/g, '') || ''
            }

            src = ImageProxyHelper.stripProxyUrl(src)
            if (src) {
              this.galleryImages.push({
                src: src,
                w: 0,
                h: 0,
                el: galleryElement
              })
            }
          } else if (data.image && data.image.value) {
            src = ImageProxyHelper.stripProxyUrl(data.image.value)
            if (src) {
              this.galleryImages.push({
                src: src,
                msrc: data.image.value,
                w: 0,
                h: 0,
                el: galleryElement,
                title: `<h4>${data.title || ''}</h4><p>${data.subtitle || ''}</p>`
              })
            }
          }

          if (!galleryElement.querySelector('a')) {
            // Don't open photoswipe when the image has a link attached
            galleryElement.addEventListener('click', event => this.openPhotoSwipe(event, index, galleryElement, false))
          }
        })
      }

      const swiper = element.querySelector('.swiper-container')

      if (swiper) {
        element.classList.add('swiper-section')
        const slides = swiper.children[0].children
        each(slides, slide => {
          ;[...slide.classList].map(className => {
            if (className.indexOf('col-') === 0) {
              slide.classList.remove(className)
            }
          })
          slide.classList.remove('content-item')
          slide.classList.remove('content-container')
          slide.classList.add('swiper-slide')
        })

        this.swiper = new this.controller.imports.Swiper(swiper, {
          slidesPerView: 1.3,
          centeredSlides: true,
          spaceBetween: 30,
          autoHeight: true,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev'
          // }
        })
      }
    } else {
      const swiper = element.querySelector('.swiper-container')
      if (swiper) {
        swiper.remove()
      }
    }
  }

  openPhotoSwipe = (event, index, galleryElement, disableAnimation) => {
    event.stopImmediatePropagation()

    const pswpElement = document.querySelectorAll('.pswp')[0]

    // define options (if needed)
    const options = {
      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),
      showHideOpacity: true,
      getThumbBoundsFn: index => {
        // See Options -> getThumbBoundsFn section of documentation for more info
        const thumbnail = this.galleryImages[index].el
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
        const rect = thumbnail.getBoundingClientRect()
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
      }
    }
    options.index = parseInt(index, 10)

    // exit if index not found
    if (isNaN(options.index)) {
      return
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0
    }

    // Pass data to PhotoSwipe and initialize it
    const gallery = new this.controller.imports.PhotoSwipe(pswpElement, this.controller.imports.PhotoSwipeUIDefault, this.galleryImages, options)
    gallery.listen('gettingData', (index, item) => {
      if (item.w < 1 || item.h < 1) {
        const image = new window.Image()
        image.onload = () => {
          item.w = image.width
          item.h = image.height
          gallery.invalidateCurrItems()
          gallery.updateSize(true)
        }
        image.src = item.src
      }
    })

    gallery.listen('destroy', () => {
      document.documentElement.classList.remove('fixed-overlay')
    })

    document.documentElement.classList.add('fixed-overlay')
    gallery.init()
  }
}

export default ListControl
