import { api, getUrl, siteNames } from '@eig-builder/core-utils/helpers/url-helper'

const ceApiUrl = (path, params) => api(`v1.0/${path}`, 'email-marketing', params)
const reApiUrl = (path, param) => api(`v1.0/${path}`, 'express-editor', param)
export const reApiV2Url = (path, param) => api(`v2.0/${path}`, 'express-editor', param)

// const reApiUrl = (path, param) => {
//   const paramStr = param && Object.keys(param).map(key => `${key}=${param[key]}`).join('&')
//   let url = `http://localhost:5000/v1/${path}`
//   if (paramStr) {
//     url += '?' + paramStr
//   }
//   return url
// }

const cpApiUrl = (path, param) => api(`v1.0/${path}`, 'control-panel', param)

const crawlrUrl = (path, param) => reApiUrl(`onboarding/crawl/${path}`, param)

const eventsUrl = (path, param) => getUrl(siteNames.EVENTS, `/v1.0/${path}`, null, param)

const storageUrl = (path, param) => getUrl('storage', `/v1.0/${path}`, null, param)

const blogViewerUrl = path => getUrl(siteNames.BLOG_VIEWER_API, `/v1.0/${path}`)

const logoApiUrl = (path, param) => api(`v1.0/${path}`, 'logos', param)

const billingApiUrl = (path, param) => api(`v1.0/${path}`, 'billing', param)

export { reApiUrl, ceApiUrl, cpApiUrl, crawlrUrl, eventsUrl, storageUrl, logoApiUrl, blogViewerUrl, billingApiUrl }
