import ImageProxyHelper from 'core/helpers/image-proxy-helper'
import LocalImageHelper from 'shared/local-image-helper'

const lastProp = obj => {
  const keys = Object.keys(obj)
  return obj[keys[keys.length - 1]]
}

const getImageSourceUrl = image => {
  return image.temp_value || image.value || image.url || lastProp(image.sizes || {})
}

/**
 *
 * @param {*} image - image
 * @param {*} size - size for the image on the cdn
 * @param {*} cdnType - the cdn to use for proxying images
 */
const getImageUrl = (image, width, cdnType = 'cloudflare') => {
  const url = getImageSourceUrl(image)
  if (!url) {
    if (image._localImage) {
      return LocalImageHelper.getImage(image._localImage)
    }
    return image.base64Image
  }

  // backwards compatibility for icons in images
  if (url.indexOf('fa-') === 0) {
    return url
  }

  // try to read image from local cache (when the image is just oploaded)
  const localImage = LocalImageHelper.getImage(url)
  if (localImage) {
    return localImage
  }

  if (url.indexOf('data:image/svg') === 0) {
    return url.replace(/"/g, '&quot;')
  }

  const imageProxyHelper = new ImageProxyHelper(url, cdnType)
  if (image.crop && image.crop.rect) {
    imageProxyHelper.crop(image.crop, image.originalSize)
  }
  imageProxyHelper.resizeToWidth(width)
  return imageProxyHelper.result()
}

export { getImageUrl, getImageSourceUrl }
