const getFirstLocation = location => {
  if (location['1']) {
    return location['1']
  }

  const keys = Object.keys(location)
  if (keys && keys.length) {
    return location[keys[0]]
  }

  return null
}

export { getFirstLocation }
