const getPageChildren = (navigation, checkNavigationItem, selector) => {
  if (!checkNavigationItem.page) {
    return []
  }

  const uri = checkNavigationItem.page.uriPath

  const result = []

  navigation.forEach((navigationItem, index) => {
    const { page } = navigationItem

    const isChild =
      page &&
      page.showInNavigation === false &&
      page.uriPath &&
      page.uriPath !== uri &&
      page.uriPath.indexOf(uri + '/') === 0

    if (isChild) {
      result.push(selector(index))
    }
  })

  return result
}

const getPageParent = (navigation, checkNavigationItem) => {
  if (!checkNavigationItem.page) {
    return
  }
  const { page } = checkNavigationItem
  const parentUri = page.showInNavigation === false && page.uriPath.substr(0, page.uriPath.lastIndexOf('/'))
  if (parentUri) {
    return navigation.find(navigationItem => navigationItem.page && navigationItem.page.uriPath === parentUri)
  }
}

const makePagesHierarchy = navigation => {
  const pageHierarchy = navigation.map(i => ({ ...i })) // clone shallow because where injecting children

  pageHierarchy.forEach((page, index) => {
    page.children = getPageChildren(navigation, navigation[index], index => pageHierarchy[index])
  })
  return pageHierarchy.filter((item, index) => !getPageParent(navigation, navigation[index]))
}

export { makePagesHierarchy }
