import CrossSVG from 'shared/images/misc/cross.svg'

class Notification {
  constructor (id, opts, Notifications) {
    this.Notifications = Notifications
    this.id = id
    this.index = null
    this.target = this._getValidTarget(opts)
    this.targetRect = this.target.getBoundingClientRect()
    this.message = opts.message
    this.type = opts.type
    this.color = this.getColorFromType(opts.type)

    this.wrapper = null
    this.notification = null
    this.closeButton = null
    this.isMovingUp = false

    this.handleClose = this.handleClose.bind(this)
  }

  _getValidTarget (opts) {
    if (opts.hasOwnProperty('target')) {
      return opts.target
    } else if (opts.hasOwnProperty('contentControl') && opts.contentControl.getSectionControl()) {
      return opts.contentControl.getSectionControl().domElement
    }
    return document.body
  }

  show (callback) {
    this.wrapper = document.createElement('div')
    this.wrapper.classList.add('feature-notification-wrapper')
    this.wrapper.setAttribute('data-id', this.id)
    this.wrapper.style.position = 'absolute'
    this.setTop((this.Notifications.notifications.length - 1) * 56)

    this.notification = document.createElement('div')
    this.notification.classList.add('feature-notification')
    this.notification.style.backgroundColor = this.color
    this.notification.innerHTML = this.message

    this.closeButton = document.createElement('div')
    this.closeButton.classList.add('feature-notification-close-button')
    this.closeButton.style.backgroundImage = `url(${CrossSVG})`
    this.closeButton.addEventListener('click', this.handleClose)
    this.notification.appendChild(this.closeButton)

    this.wrapper.appendChild(this.notification)
    this.target.appendChild(this.wrapper)

    window.requestAnimationFrame(() => {
      this.wrapper.classList.add('feature-notification-visible')
      setTimeout(() => this.triggerCallback(callback), this.Notifications.config.animationSpeed)
    })
  }

  hide (callback) {
    this.wrapper.classList.remove('feature-notification-visible')
    setTimeout(() => {
      this.closeButton.removeEventListener('click', this.handleClose)
      this.target.removeChild(this.wrapper)
      this.triggerCallback(callback)
    }, this.Notifications.config.animationSpeed)
  }

  handleClose (e) {
    this.Notifications.closeNotification(this.id)
  }

  setTop (top) {
    this.wrapper.style.top = top + 'px'
  }

  getColorFromType (type) {
    switch (type) {
      case 0:
      case 'success': {
        return '#8BC34A'
      }
      case 1:
      case 'neutral': {
        return '#2196F3'
      }
      case 2:
      case 'error': {
        return '#F44336'
      }
    }
  }

  triggerCallback (callback) {
    if (typeof callback === 'function') {
      callback()
    }
  }
}

export default Notification
