import remove from 'lodash/remove'
import { makePagesHierarchy } from './page-hierarchy'
import sortBy from 'lodash/sortBy'

function getNavigationInOrder (navigation, remaingPages) {
  navigation.forEach(navigationItem => {
    if (typeof navigationItem.pageId !== 'undefined') {
      const page = remaingPages.find(i => i.id === navigationItem.pageId)
      if (!page) {
        // our data is corrupt, page isn't there
        console.error('page not found for site')
      } else {
        remove(remaingPages, i => i === page)
      }
    }
  })
  return navigation
}

// result = current navigationItems + (new navigation items from pages that don't have navigationitems yet)
function createCompleteNavigationItems (pages, navigation, currentNavigation = null) {
  const remainingPages = [...pages]

  let navigationItems = getNavigationInOrder(currentNavigation || navigation || [], remainingPages)

  let id = 0
  let orderNumber = navigationItems.length ? Math.max.apply(null, navigationItems.map(i => i.order)) + 1 : 0
  navigationItems = navigationItems.concat(
    remainingPages
      .filter(Boolean)
      .map(page => {
        return {
          id: new Date().getTime() + id++,
          pageId: page.id,
          order: orderNumber++
        }
      })
  )

  return sortBy(navigationItems, 'order')
}

export { createCompleteNavigationItems }

// helper function to abstract some logic away,
// make navigation items with a reference to the actual page object for convenience
// also include the title of the page in the navigation item so we don't have to do the logic every (if page then title: page.title)
export const getVirtualNavigationItems = (pages, currentNavigation) => {
  return currentNavigation.map(nav => {
    if (typeof nav.pageId !== 'undefined') {
      const page = pages.find(i => i.id === nav.pageId)
      if (!page) {
        // our data is corrupt, page isn't there
        return null
      }

      return {
        ...nav,
        page,
        title: page.title
      }
    }
    return nav
  }).filter(Boolean)
}

export const getVirtualNavigationHierarchy = virtualNavItems => {
  return makePagesHierarchy(virtualNavItems)
}
