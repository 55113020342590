import Handlebars from 'handlebars'
import GlobalProps from 'editor/controls/handlebars/global-props'

Handlebars.registerHelper('ifPopover', function (value) {
  if (GlobalProps.isRuntime && this.showPopover) {
    return value.fn(this)
  } else {
    return null
  }
})
