import BaseController from '../base-controller'

class ListItemController extends BaseController {
  innerGetValue (model) {
    const content = this.getContentController()
    const obj = {
      ...model,
      parent: content.model
    }
    return obj
  }

  getValue = model => {
    return this.innerGetValue(model)
  }
}

export default ListItemController
