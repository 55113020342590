import Handlebars from 'handlebars'
import Layouts from '../managers/layouts'
import LogError from './log-error'

class LayoutHelper {
  static getFileNameFromPathOrParam (layoutsSettings, propName, fileName) {
    if (!fileName && layoutsSettings && layoutsSettings[propName].path) {
      const pathSplit = layoutsSettings[propName].path.split('/')
      if (pathSplit.length === 3) {
        fileName = pathSplit[2]
      }
    }
    return fileName
  }

  static getLayoutObj (layoutsSettings, propName, fileName) {
    fileName = LayoutHelper.getFileNameFromPathOrParam(layoutsSettings, propName, fileName)
    return LayoutHelper.internalGetLayoutObj(layoutsSettings[propName], fileName, propName)
  }

  static internalGetLayoutObj (layoutsObj, fileName, propName) {
    if (!layoutsObj) {
      // console.error(`section data expected to have layout.${propName}`)
      return
    }

    const layoutObjObject = Layouts.getLayoutObject()
    let layout
    if (layoutObjObject) {
      // load from layout definition
      layout = layoutsObj.id ? layoutObjObject.getById(layoutsObj.id) : layoutObjObject.get(layoutsObj.path)
    } else {
      // load inline layout
      layout = layoutsObj
    }

    if (layout) {
      if (!layout.compiledLayouts) {
        layout.compiledLayouts = {}
      }

      if (fileName) {
        if (!layout.html_templates[fileName] && !layout.compiledLayouts[fileName] && (fileName === 'left' || fileName === 'right')) {
          if (layout.html_templates.index || layout.compiledLayouts.index) {
            fileName = 'index'
          }
        }

        if (!layout.compiledLayouts[fileName]) {
          if (!layout.html_templates || !layout.html_templates[fileName]) {
            throw new Error(`${layout.path}/${fileName}.html not found`)
          }
          const html = layout.html_templates[fileName].trim()

          layout.compiledLayouts[fileName] = Handlebars.compile(html)
        }
      } else {
        // fallback to default layout
        if (!layout.html && layout.html_templates) {
          layout.html = layout.html_templates[Object.keys(layout.html_templates)[0]]
        }
        if (!layout.compiledLayouts.index && layout.html) {
          // compile index.html
          const html = layout.html.trim()

          layout.compiledLayouts.index = Handlebars.compile(html)
        }
      }
    }

    return layout
  }

  static hasLayoutFile (layoutsSettings, propName, fileName) {
    const layoutObjObject = Layouts.getLayoutObject()
    if (!layoutObjObject) {
      return false
    }
    const layoutsObj = layoutsSettings[propName]
    const layout = layoutsObj.id ? layoutObjObject.getById(layoutsObj.id) : layoutObjObject.get(layoutsObj.path)
    return fileName ? layout.html_templates && layout.html_templates[fileName] : layout.html
  }

  static getLayout (layoutsSettings, propName, fileName) {
    fileName = LayoutHelper.getFileNameFromPathOrParam(layoutsSettings, propName, fileName)

    const layout = LayoutHelper.internalGetLayoutObj(layoutsSettings[propName], fileName, propName)

    if (layout) {
      if (fileName && fileName !== 'index' && !layout.compiledLayouts[fileName]) {
        fileName = 'index'
      }

      const result = layout.compiledLayouts[fileName || 'index']
      if (!result) {
        LogError.log(`layout not found: ${propName}/${fileName || 'index'}`)
        LogError.log(`Try to add this to the settings.json in your feature:\n"publishLayoutsWithRuntimeSite": ["${propName}"]`)
      }
      return result
    }
  }
}

export default LayoutHelper
