import get from 'lodash/get'

export default function (binding, property) {
  let toggle = false
  if (binding._controlType === 'list-item') {
    toggle = get(binding, `parent._toggle._${binding._controlType}`)
  } else {
    toggle = binding._toggle
  }

  // return true when there is no toggle or is set to true
  return !toggle || toggle[property] !== false
}
