import Handlebars from 'handlebars'
import GlobalProps from './global-props'
import some from 'lodash/some'
import toggleIsOn from './helpers/toggle-helper'

Handlebars.registerHelper('editable', function (value) {
  const fnTrue = value.fn
  const fnFalse = value.inverse

  return GlobalProps.editable ? fnTrue(this) : fnFalse(this)
})

Handlebars.registerHelper('isOn', function (prop, value) {
  const fnTrue = value.fn
  const fnFalse = value.inverse

  return toggleIsOn(value.data.root, prop) ? fnTrue(this) : fnFalse(this)
})

// opposite of isOn
Handlebars.registerHelper('isOff', function (prop, value) {
  const fnTrue = value.fn
  const fnFalse = value.inverse

  return toggleIsOn(value.data.root, prop) ? fnFalse(this) : fnTrue(this)
})

Handlebars.registerHelper('hasField', function (prop, value) {
  const toggle = value.data.root._toggle

  const fnTrue = value.fn
  const fnFalse = value.inverse

  return !toggle || some(prop.split(' '), field => toggle[field] !== false)
    ? fnTrue(value.data.root)
    : fnFalse(value.data.root)
})
