import some from 'lodash/some'
import each from 'lodash/each'

import SassFunctionParser from '../../utils/sass-function-parser'

import ColorFunctions from '../../utils/color-functions'

import LogError from '../../utils/log-error'

const MINIMAL_TEXT_CONTRAST = 0.4
const MAX_DARKEN_TEXT_COLOR = 13
const MAX_LIGHTEN_TEXT_COLOR = 16

const black = [0, 0, 0]
const white = [255, 255, 255]

const contrast = (color1, color2) => {
  return ColorFunctions.contrastWithAlpha(color1, color2)
}

const colorEquals = (color1, color2) => {
  return color1[0] === color2[0] && color1[1] === color2[1] && color1[2] === color2[2]
}

const mixColor = (color1, color2, fraq) => {
  const arr = [0, 0, 0]
  for (let i = 0; i < 3; i++) {
    arr[i] = color1[i] + (color2[i] - color1[i]) * fraq
  }
  if (color1[3]) {
    arr[3] = color1[3] + ((color2[3] || 0) - color1[3]) * fraq
  } else {
    arr[3] = (color2[3] || 0) * fraq
  }
  return arr
}

const imageVisibleThroughColor = color => {
  return color[3] && color[3] > 0.1
}

const PickColor = (colorPallet, index) => {
  // pick back and text colors first, after that pick from accent array
  switch (index) {
    case 0:
      return colorPallet.background
    case 1:
      return colorPallet.text
    default: {
      index -= 2
      const accentLength = colorPallet.accent.length

      if (index < accentLength) {
        return colorPallet.accent[index]
      }
      switch (index - accentLength) {
        case 0:
          return white
        case 1:
          return black
      }
    }
  }
}

const pickAccentColor = (colorPallet, index) => {
  // pick out of the accent array first, after that for and backgrounds, ultimate fallback is black and white
  const accentLength = colorPallet.accent.length
  if (index < accentLength) {
    return colorPallet.accent[index]
  }
  switch (index - accentLength) {
    case 0:
      return colorPallet.text
    case 1:
      return colorPallet.background
    case 2:
      return white
    case 3:
      return black
  }
}

const pickTextColor = (colorPallet, index) => {
  return PickColor(colorPallet, index)
}

const pickTitleColor = (colorPallet, index) => {
  return PickColor(colorPallet, index)
}

const pickTitle2Color = (colorPallet, index) => {
  const accentLength = colorPallet.accent.length
  if (index < accentLength) {
    return colorPallet.accent[index]
  }
  return pickTextColor(colorPallet, index - accentLength)
}

const internalPickContrastColor = (colorPallet, contrastColor, exclude, picker, minimalContrastValue, canAdjustColor) => {
  // return null when all colors exist in exclude list
  let color
  let index = 0
  while ((color = picker(colorPallet, index++, contrastColor))) {
    if (!some(exclude, ex => colorEquals(ex, color)) && !ColorFunctions.equals(color, contrastColor)) {
      if (contrast(color, contrastColor) >= minimalContrastValue) {
        return color
      } else {
        if (
          canAdjustColor &&
          (!contrastColor[3] || contrastColor[3] < 0.7) // skip code if we have a very visible background image
        ) {
          color = ColorFunctions.increaseConstrast(color, contrastColor, MAX_LIGHTEN_TEXT_COLOR, MAX_DARKEN_TEXT_COLOR) // make color darker or lighter
          if (contrast(color, contrastColor) >= minimalContrastValue) {
            return color
          }
        }
      }
    }
  }

  // retry and ignore previous colors
  index = 0
  while ((color = picker(colorPallet, index++, contrastColor))) {
    if (contrast(color, contrastColor) >= minimalContrastValue) {
      return color
    }
  }
}

class ColorManager {
  constructor (siteController) {
    this.siteController = siteController

    this.colorPallet = siteController.getTheme().colors

    this.baseStyle = siteController.baseStyle

    if (!this.getTree()) {
      this.setTree({})
    }
    // this.parse() moved to the render of a section
  }

  // generate a unique key of the current color pallete, to prevent duplicate css generation. Note: it's not actually a hash, but it could be
  colorPaletteHash () {
    let result = ''
    for (let i = 0; i < 4; i++) {
      result += ColorFunctions.toRgb(PickColor(this.colorPallet, i))
    }
    return result
  }

  getTree () {
    return this.colorTree
  }

  setTree (newTree) {
    this.colorTree = newTree
  }

  hasWhiteBackground () {
    const color = this.getBackgroundColor()
    if (imageVisibleThroughColor(this.getTree()._color)) {
      return false
    }
    return color && ColorFunctions.colorLightness(color[0], color[1], color[2]) >= 0.99
  }

  /* checkContrasts (tree) {
    // recalc all text colors on background
    tree.text = []
    tree.card = []

    if (!this.getBackgroundIsImage() || this.getBackgroundOpacity() < 5) {
      // delete colors that make no contrast anymore
      const backgroundColor = this.getBackgroundColor()
      const deleteNodes = {}
      for (let colorName in tree) {
        if (colorName !== '_color') {
          each(tree[colorName], (item, itteration) => {
            if (contrast(item._color, backgroundColor) < 0.05) {
              deleteNodes[colorName] = Math.min(deleteNodes[colorName] || 100000, itteration)
            }
          })
        }
      }

      for (let colorName in deleteNodes) {
        tree[colorName].splice(deleteNodes[colorName], 1)
      }
      return !isEmpty(deleteNodes)
    }
  } */

  changeBackground = () => {
    // this.checkContrasts(this.getTree())
    this.setTree({}) // reset everything, Hendrik's wish
    this.parse()
  }

  changeLayout = () => {
    this.parse()
  }

  // combina varables from global styling, and list and item layout styling
  getUniqueVariables () {
    const vars = {}

    this.iterateVariabeles(variable => {
      vars[variable.variable_name] = variable
    })

    return vars
  }

  pickContrastColor (colorPallet, contrastColor, exclude, parsedVarStep, minimalContrastValue) {
    switch (parsedVarStep.name) {
      case 'accent':
        return internalPickContrastColor(colorPallet, contrastColor, exclude, pickAccentColor, minimalContrastValue)
      case 'text': {
        const picker = parsedVarStep.itteration === 0 ? pickTextColor : pickAccentColor
        return internalPickContrastColor(colorPallet, contrastColor, exclude, picker, minimalContrastValue, true)
      }
      case 'title': {
        const picker = parsedVarStep.itteration === 0 ? pickTitleColor : pickTitle2Color
        return internalPickContrastColor(colorPallet, contrastColor, exclude, picker, minimalContrastValue, true)
      }
      case 'black':
        return black
      case 'white':
        return white
      case 'card': {
        const colorLightness = ColorFunctions.colorLightness(contrastColor[0], contrastColor[1], contrastColor[2])
        if (
          !imageVisibleThroughColor(contrastColor) &&
          // check for very dark colors or dark colors with low saturation
          (colorLightness < 0.15 || (colorLightness < 0.3 && ColorFunctions.rgbToHsl(...contrastColor)[1] < 50))
        ) {
          const color = ColorFunctions.lighten(contrastColor[0], contrastColor[1], contrastColor[2], 1, 7)
          return color.slice(0, 3) // aplha channel is abused for image background, strip alpha channel
        } else {
          return white
        }
      }
      case 'background': {
        let color = this.getBackgroundColor(true)
        if (!this.getBackgroundIsImage()) {
          color = ColorFunctions.makeAdjacentColor(color)
        }
        return color
      }
      case 'customtext': {
        return PickColor(colorPallet, parsedVarStep.itteration)
      }
      default:
        return white
    }
  }

  parseColorVariable (variable) {
    // $text1_accent1-50
    const split = variable.split('_')
    const parsedVariable = []
    for (let i = split.length - 1; i >= 0; i--) {
      const value = split[i]
      if (value !== 'back') {
        let name
        let alpha
        let itteration
        const dash = value.indexOf('-')
        if (dash >= 0) {
          itteration = parseInt(value.substr(dash - 1, 1))
          name = value.substr(i === 0 ? 1 : 0, dash - (i === 0 ? 2 : 1) + (isNaN(itteration) ? 1 : 0))
          alpha = parseInt(value.substr(dash + 1)) / 100
        } else {
          itteration = parseInt(value.substr(value.length - 1, 1))
          name = value.substr(i === 0 ? 1 : 0, value.length - (i === 0 ? 2 : 1) + (isNaN(itteration) ? 1 : 0))
          alpha = 1
        }
        parsedVariable.push({
          alpha,
          name,
          itteration: (itteration || 1) - 1,
          isText: name.indexOf('text') === 0 || name.indexOf('title') >= 0
        })
      }
    }
    return parsedVariable
  }

  pickTextColor (colorPallet, backgroundColor, textVariableName, parsedVarStep) {
    const possibleColors = []
    // if (parsedVarStep.itteration > 0) {
    //   // text 2 and such, skip text and background colors so they pick accent colors
    //   possibleColors.push(colorPallet.background)
    //   possibleColors.push(colorPallet.text)
    // }

    let color
    while ((color = this.pickContrastColor(colorPallet, backgroundColor, possibleColors, parsedVarStep, MINIMAL_TEXT_CONTRAST))) {
      if (possibleColors.some(c => c.length === color.length && c.every((value, index) => color[index] === value))) {
        break
      }
      possibleColors.push(color)
    }

    if (possibleColors.length === 0) {
      // only no colors found when this is on an image
      return white
    }

    if (possibleColors.length === 1) {
      return possibleColors[0]
    }

    let index = 0
    let maxContrast = 0
    let maxContrastIndex = -1

    while (index < possibleColors.length && maxContrast < MINIMAL_TEXT_CONTRAST) {
      const color = possibleColors[index]
      let minContrast = 1

      const rgbColor = ColorFunctions.toRgba(color)
      this.iterateVariabeles(variable => {
        if (variable.variable_name === textVariableName) {
          // check for exact style name or for function calls (with a '('), we have to skip stuff like: 'solid 1px $text'
          if (variable.style_value === textVariableName || variable.style_value.indexOf('(') >= 0) {
            // apply all scss function to color and find least contrasting
            const parsedColor = ColorFunctions.strToRgb(SassFunctionParser.parse(variable.style_value, variable.variable_name, rgbColor))
            minContrast = Math.min(minContrast, contrast(backgroundColor, parsedColor))
          }
        }
      })

      // remember max contrast
      if (minContrast > maxContrast) {
        maxContrastIndex = index
        maxContrast = minContrast
      }

      index++
    }

    if (maxContrastIndex >= 0) {
      return possibleColors[maxContrastIndex]
    }
  }

  walkColorTree (vars, onVisitLeaff) {
    const tree = this.getTree()
    let currentNode = tree
    let currentColor = tree._color

    for (let i = 0; i < vars.length; i++) {
      const parsedVarStep = vars[i]
      const nodeItterations = currentNode[parsedVarStep.name]

      // to next part in variable name
      currentNode = nodeItterations[parsedVarStep.itteration]

      const previousColor = currentColor
      if (currentNode._color) {
        // step to next color and calculate its value
        if (parsedVarStep.alpha === 1) {
          currentColor = currentNode._color
        } else {
          currentColor = mixColor(currentColor, currentNode._color, parsedVarStep.alpha)
        }
      }

      if (i === vars.length - 1) {
        onVisitLeaff(parsedVarStep, currentNode, previousColor)
      }
    }
  }

  render () {
    if (!this.didParseFlag) {
      this.parse()
    }
  }

  internalParse () {
    this.didParseFlag = true
    try {
      const colorPallet = this.colorPallet
      const hasBackgroundImage = this.getBackgroundIsImage()

      const variables = this.getUniqueVariables()
      if (variables) {
        const tree = this.getTree()
        if (hasBackgroundImage) {
          tree._color = this.getBackgroundColor().slice(0)
          // the alpha channel is misused as opacity for the background
          tree._color.push(this.getBackgroundOpacity() / 100)
        } else {
          tree._color = this.getBackgroundColor().slice(0)
        }

        const textVariables = {}

        // first pass: determine BACKGROUND colors (all non-text colors), and put values in a tree
        each(variables, variable => {
          try {
            if (!variable.parsedVariableName) {
              variable.parsedVariableName = this.parseColorVariable(variable.variable_name)
            }

            const vars = variable.parsedVariableName
            let currentNode = tree
            for (let i = 0; i < vars.length; i++) {
              const parsedVarStep = vars[i]
              const nodeItterations = currentNode[parsedVarStep.name] || (currentNode[parsedVarStep.name] = [])

              while (nodeItterations.length <= parsedVarStep.itteration) {
                let treeValue
                if (parsedVarStep.isText) {
                  // pick colors later by refering to variable name
                  treeValue = {}
                } else {
                  if (!currentNode._color) {
                    throw new Error(`error in ${variable.variable_name}, parent color not found`)
                  }
                  const c = this.pickContrastColor(colorPallet, currentNode._color, nodeItterations.map(c => c._color), parsedVarStep, 0.1)
                  treeValue = {
                    _color: c.slice(0)
                  }
                }
                nodeItterations.push(treeValue)
              }

              // to next part in variable name
              currentNode = nodeItterations[parsedVarStep.itteration]

              // calc text color later and remember by variable name
              if (parsedVarStep.isText) {
                textVariables[variable.variable_name] = variable.parsedVariableName
              }
            }
          } catch (e) {
            LogError.log(`error parsing color variable: ${variable.variable_name}, ${variable.selector_text}: ${variable.style_value}`, e)
          }
        })

        // second pass, determine TEXT colors and prosess scss function and check contrast with background colors
        for (const textVariableName in textVariables) {
          const vars = textVariables[textVariableName]

          this.walkColorTree(vars, (parsedVarStep, currentNode, previousColor) => {
            if (parsedVarStep.isText && !currentNode._color) {
              currentNode._color = this.pickTextColor(colorPallet, previousColor, textVariableName, parsedVarStep).slice(0)
            }
          })
        }

        if (this.didParse) {
          this.didParse()
        }
      }
    } catch (ex) {
      LogError.log('error parsing colors', ex)
    }
  }

  parse () {
    this.internalParse()
  }
}

export default ColorManager

export { PickColor }
