import ColorManager from './base-color-manager'
import StyleLoader from '../../utils/style-loader'
import each from 'lodash/each'

// per section
class SiteColorManager extends ColorManager {
  constructor (sectionController, siteController, backgroundController, stylesheetCollector) {
    super(siteController)
    this.backgroundController = backgroundController
    this.sectionController = sectionController
    this.stylesheetCollector = stylesheetCollector
  }

  getBackgroundIsImage () {
    return this.backgroundController.hasMedia()
  }

  getBackgroundOpacity () {
    const back = this.backgroundController.model
    const opacityInt = parseInt(back.opacity)
    return !isNaN(opacityInt) ? opacityInt : 100
  }

  getBackgroundColor (excludeOverlayColor) {
    return this.backgroundController.getBackgroundColor(excludeOverlayColor)
  }

  updateCss () {
    this.setTree({})
    this.iterateLayouts(layout => {
      StyleLoader.loadOrUpdateLayout(layout, this)
    })
  }

  didParse () {
    // nothing
  }

  getSectionsLayouts () {
    const result = []
    const layoutSettings = this.sectionController.getLayoutModel()
    if (layoutSettings.section) {
      result.push(this.sectionController.getSectionLayoutObj())
    }
    if (layoutSettings.listItem) {
      result.push(this.sectionController.getListItemLayoutObj())
    }
    return result
  }

  parseTree () {
    this.layouts = this.baseStyle ? [this.baseStyle] : []
    this.layouts.concat(this.getSectionsLayouts())
    this.internalParse()
  }

  parse () {
    if (this.baseStyle) {
      this.layouts = [this.baseStyle]
      this.updateCss()
    }

    this.layouts = this.getSectionsLayouts()
    this.updateCss()
  }

  iterateLayouts (callback) {
    this.layouts.forEach(callback)
  }

  iterateVariabeles (callback) {
    this.iterateLayouts(layout => {
      each(layout.style_variables, callback)
    })
  }

  changeColorPalette = () => {
    this.colorPallet = this.siteController.getTheme().colors

    // rerender background, this will call back to this class to re-parse colors
    this.backgroundController.updateView(this.backgroundController.control, this.backgroundController.model, {
      'theme.colors': true
    })
  }
}

export default SiteColorManager
