import BaseControl from '../base-control/base-control'

import $ from 'jquery'
// import each from 'lodash/each'
// import maxBy from 'lodash/maxBy'
// import reverse from 'lodash/reverse'
// import Features from 'editor/managers/features'
import Layout from '../templates/page-control.tpl'
import { selectByScroll, findSectionInView } from 'shared/select-by-scroll-helper'

class PageControl extends BaseControl {
  constructor (controller) {
    super(controller, () => Layout, controller.model, 'page')

    this.lazyImageSelector = ''
  }

  getViewPort () {
    const fixedHeader = this.fixedHeader || document.querySelector('.kv-fixed-header')
    return {
      scrollY: window.pageYOffset,
      topPadding: fixedHeader ? fixedHeader.offsetHeight : 0,
      windowHeight: window.innerHeight
    }
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    const siteController = this.controller.getSiteController()

    // Features.forEachActiveFeatureInstance(feature => {
    //   if (feature.afterRenderPage) {
    //     feature.afterRenderPage(this, element)
    //   }
    // })

    // if (this.controller.imports.Velocity) {
    this.initialized = false
    this.fixedHeader = document.querySelector('.kv-fixed-header')

    if (siteController.hasSectionBasedNavigation()) {
      this.sections = Array.prototype.slice.call(element.querySelectorAll('section'))
      this.activeSection = findSectionInView(this.sections, this.getViewPort())
      window.addEventListener('scroll', this.onWindowScroll)
      window.addEventListener('resize', this.onWindowResize)
    }

    // Animations
    if (this.controller.isRuntime() && this.controller.hasAnimations() && window.IntersectionObserver) {
      const animationSettings = (this.animationSettings = siteController.getTheme().animations)

      this.observer = new window.IntersectionObserver(this.onElementIntersect, {
        // If element gets within 50px go get it
        rootMargin: '50px 0px',
        threshold: 0.01
      })

      this.initializeAnimationElements(element, animationSettings)
    } else {
      // Remove class .kv-notify-inview from all elements
      const nodeList = element.querySelectorAll('.kv-notify-inview')
      Array.prototype.forEach.call(nodeList, node => node.classList.remove('kv-notify-inview'))
    }
    // }
  }

  dispose () {
    super.dispose()

    window.removeEventListener('scroll', this.onWindowScroll)
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize = () => {
    this.initialized = false
  }

  onWindowScroll = () => {
    selectByScroll({
      ...this.getViewPort(),
      sections: this.sections,
      activeSection: this.activeSection,
      onSelectSection: newActiveSection => {
        this.activeSection = newActiveSection
        const controls = this.controller.getChildControls()
        const activeControl = controls.find(i => i.domElement === newActiveSection)

        const sectionNavigations = document.querySelectorAll('.kv-site:not(.kv-edit-mode) *[data-uri-path]')
        const sectionNavigation =
          activeControl &&
          document.querySelector(
            '.kv-site:not(.kv-edit-mode) *[data-uri-path="section-' + activeControl.model.id + '"]'
          )

        for (let i = 0; i < sectionNavigations.length; i++) {
          sectionNavigations[i].classList.remove('active')
        }
        if (sectionNavigation) {
          sectionNavigation.classList.add('active')
        }
      }
    })
  }

  initializeAnimationElements = (pageElement, animationSettings) => {
    const elements = pageElement.querySelectorAll(
      '.kv-notify-inview, *[data-type="title"], *[data-type="button"], *[data-type="map"], *[data-type="image"], *[data-type="link"], *[data-type="text"], *[data-type="item"], *[data-type="social"], .embed-container, .list-inline, input, textarea, button, select'
    )
    // const elements = pageElement.querySelectorAll('*[data-animation]')

    elements.forEach(element => {
      const $element = $(element)

      if ($element.hasClass('kv-notify-inview')) {
        if (this.observer) {
          this.observer.observe(element)
        }
        return true
      }

      if (
        $element.parents('*[data-type]:not(*[data-type="list"], .simpleParallax, .swiper-container, .no-animation)')
          .length === 0
      ) {
        element.style.opacity = 0
        if (this.observer) {
          this.observer.observe(element)
        }
      }

      if ($element.parent('.simpleParallax').length > 0) {
        element.parentElement.style.opacity = 0
        if (this.observer) {
          this.observer.observe(element.parentElement)
        }
      }
    })
  }

  onElementIntersect = entries => {
    // Loop through the entries
    entries.forEach(entry => {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {
        // Stop watching and load the image
        const element = entry.target

        const distance = this.animationSettings.distance
        const duration = this.animationSettings.duration

        this.observer.unobserve(element)
        if (element.classList.contains('kv-notify-inview')) {
          element.classList.add('kv-inview')
        } else {
          this.controller.imports.Velocity(
            element,
            { translateY: [0, distance], opacity: [1, 0] },
            duration,
            'ease-in-out'
          )
        }
      }
    })
  }
}

export default PageControl
