let hasEvents = false
const fitTextElements = []

class FitTextMixin {
  constructor () {
    if (typeof document === 'undefined') {
      return
    }

    // Add a dummy to measure width of text in specific font
    let dummy = document.getElementById('font-measurement-dummy')
    if (!dummy) {
      dummy = document.createElement('span')
      dummy.id = 'font-measurement-dummy'
      Object.assign(dummy.style, {
        visibility: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        whiteSpace: 'nowrap'
      })
      document.body.appendChild(dummy)
    }
    this.dummy = dummy

    // resize and orientation change events
    if (!hasEvents) {
      window.addEventListener('resize', this.resizer, false)
      window.addEventListener('orientationchange', this.resizer, false)
      hasEvents = true
    }
  }

  bindLayout (element) {
    // find all elements to match font width
    window.requestAnimationFrame(() => {
      const elements = [...element.querySelectorAll('.fit-text')]
      this.element = element
      elements.forEach(el => {
        if (!el.dataset.fitted) {
          fitTextElements.push(el) // remember element for later
          el.fitText = () => {
            el.style.width = '100%' // ensure the element is stretched
            el.style.fontSize = 'initial'
            let matchingFontSize = this.calculateFontSize(el)

            if (matchingFontSize !== 0) {
              // made fontsize smaller (in pixels) to prevent wrapping. this way the text will fit inside the container.
              matchingFontSize -= 0.1
              // clamp the text (in pixels)
              const minFontSize = 20
              const maxFontSize = 60
              matchingFontSize = this.clamp(matchingFontSize, minFontSize, maxFontSize)
              el.style.fontSize = `${matchingFontSize}px`
            }
          }
          el.fitText()
          el.dataset.fitted = true
        }
      })
    })
  }

  calculateFontSize (el) {
    // calculate the matching fontsize in container
    const styles = window.getComputedStyle(el)
    const fontSize = Number.parseFloat(styles.getPropertyValue('font-size'))
    const fontFamily = styles.getPropertyValue('font-family')
    const dummy = this.dummy
    dummy.innerText = el.textContent
    dummy.style.fontSize = `${fontSize}px`
    dummy.style.fontFamily = fontFamily.split(':')[0]
    const matchingFontSize = fontSize * (el.offsetWidth / dummy.offsetWidth)
    return matchingFontSize
  }

  clamp (num, min, max) {
    return Math.min(Math.max(num, min), max)
  }

  resizer () {
    fitTextElements.forEach(el => {
      el.fitText()
    })
  }

  /* This seems to override base control's disposeElement function causing page removal to break. */

  // disposeElement () {
  //   // Return when no container is known
  //   if (!this.element) {
  //     return
  //   }

  //   // Remove elements inside container when disposed
  //   const elements = [...this.element.querySelectorAll('.fit-text')]
  //   elements.forEach((el) => {
  //     const i = fitTextElements.indexOf(el)
  //     if (i !== -1) {
  //       fitTextElements.splice(i, 1)
  //     }
  //   })
  // }
}
export default FitTextMixin
