import BaseControl from '../base-control/base-control'

import '../style/site-control.scss'
import Layout from '../templates/site-control.tpl'

class SiteControl extends BaseControl {
  constructor (controller) {
    super(controller, () => Layout, IS_MAIL ? controller.model : controller.model.pages, 'site')

    this.lazyImageSelector = ''
  }
}

export default SiteControl
