import Store from '@eig-builder/core-utils/store'
import ConfigurationManager from 'editor/managers/configuration-manager'
import CheckoutHelper from '@eig-builder/core-utils/helpers/checkout-helper'
import { api as urlHelperApi } from '@eig-builder/core-utils/helpers/url-helper'

import LimitationsManager from 'editor/managers/limitations-manager'
import { isHostedBrand } from 'shared/account-type-helper'

export default (extraParameters = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { auth, api } = Store().getState()

      let upgradeUrl
      if (isHostedBrand() && extraParameters.limitationName) {
        upgradeUrl = LimitationsManager.getUpgradeUrl(extraParameters.limitationName)
      }

      if (!upgradeUrl) {
        upgradeUrl = ConfigurationManager.getUpgradeUrl(api.siteId)
      }

      if (upgradeUrl) {
        window.open(upgradeUrl, '_blank')
        resolve()
        return
      }

      const win = window.open('', '_blank')
      const apiUrl = urlHelperApi('')

      await isBuyingAllowed(auth)
      CheckoutHelper.buySite(null, api.siteId, null, 'new_cp_my_sites_upgrade_site', extraParameters, {
        apiUrl: apiUrl,
        goToAction: url => {
          win.location.href = url
          win.focus()
          resolve()
        }
      })
    } catch (ex) {
      reject(ex)
    }
  })
}

const isBuyingAllowed = auth => {
  return new Promise((resolve, reject) => {
    if (!auth.hasOrdersPending) {
      if (auth.billingInfo && auth.billingInfo.needPayPalCredentials === true) {
        reject(new Error('PayPal credentials missing.'))
      } else {
        resolve()
      }
    }
  })
}
