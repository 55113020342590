import ContentController from './controllers/readonly/content-controller'
import BackgroundController from './controllers/readonly/background-controller'
import ListController from './controllers/readonly/list-controller'
import ListItemController from './controllers/readonly/list-item-controller'
import NavigationController from './controllers/readonly/navigation-controller'
import PageController from './controllers/readonly/page-controller'
import SectionController from './controllers/readonly/section-controller'
import SiteController from './controllers/readonly/site-controller'

import BackgroundControl from './controls/readonly/background-control'
import ContentControl from './controls/readonly/content-control'
import ListControl from './controls/readonly/list-control'
import ListItemControl from './controls/readonly/list-item-control'
import NavigationControl from './controls/readonly/navigation-control'
import PageControl from './controls/readonly/page-control'
import SectionControl from './controls/readonly/section-control'
import SwiperControl from './controls/readonly/swiper-control'
import SiteControl from './controls/readonly/site-control'

const imports = {
  SiteController,
  ContentController,
  BackgroundController,
  ListController,
  ListItemController,
  NavigationController,
  PageController,
  SectionController,
  // control
  BackgroundControl,
  ContentControl,
  ListControl,
  ListItemControl,
  NavigationControl,
  PageControl,
  SectionControl,
  SwiperControl,
  SiteControl
}

export default imports
