import each from 'lodash/each'
import '../../handlebars/helpers'

const makeDomElement = html => {
  const template = document.createElement('div')
  template.innerHTML = html
  return template.childNodes[0]
}

class SiteRenderer {
  render (parentBinding, index) {
    this.renderPending = false

    let html
    if (parentBinding) {
      html = this.renderHtml(parentBinding, index)
    } else {
      html = this.renderHtml(this._parentBinding, this._index)
    }

    const newElement = makeDomElement(html)

    if (this.controller.editable()) {
      // Disable links for editor
      each(newElement.querySelectorAll('a'), e => {
        if (e.closest('[data-type="text"]')) {
          if (e.dataset.href && !e.href) {
            e.href = e.dataset.href
          }

          if (typeof window !== 'undefined' && window.top !== window.self) {
            if (!e.href.startsWith(window.location.origin)) {
              e.target = '_blank'
            }
          }
          return true
        }

        if (e.href && e.href.indexOf('#') === -1) {
          e.dataset.href = e.href
          e.removeAttribute('href')
        }
        if (e.target) {
          e.dataset.target = e.target
          e.removeAttribute('target')
        }
      })
    }

    this.setElement(newElement, true)
    return newElement
  }
}

export default SiteRenderer
