import * as actions from '../../store/popovers/actions'

let currentElement = null

class Popovers {
  static registerDispatch (dispatch) {
    this.dispatch = dispatch
  }

  static update (id, data) {
    this.dispatch(actions.updatePopoverWindow({ id, data }))
  }

  static activate (data) {
    if (!data.hasOwnProperty('id')) {
      return new Promise((resolve, reject) => {
        data.callback = id => resolve(id)
        data.error = err => reject(err)
        this.dispatch(actions.activatePopoverWindow(data))
      })
    } else {
      this.dispatch(actions.activatePopoverWindow(data))
    }
  }

  static deactivate (id) {
    this.dispatch(actions.deactivatePopoverWindow(id))
  }

  static setCurrentElement (element) {
    currentElement = element
  }

  static getCurrentElement () {
    return currentElement
  }

  static setOrientation (orientation) {
    this.dispatch(actions.setPopoverWindowOrientation(orientation))
  }
}

export default Popovers
