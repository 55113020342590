import BaseControl from '../base-control/base-control'

import Layout from '../templates/section-control.tpl'
class SectionControl extends BaseControl {
  constructor (controller, model) {
    super(controller, () => Layout, model, 'section')

    this.widgetId = ''
    this.element = ''
  }

  renderHtml (parentBinding, index) {
    if (this.controller.colorManager) {
      this.controller.colorManager.render()
    }
    return super.renderHtml(parentBinding, index)
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)
    this.element = element
    this.data = data

    /* Place yer section logic here */
  }
}

export default SectionControl
