import BaseControl from '../base-control/base-control'

class ListItemControl extends BaseControl {
  constructor (controller, layout, model) {
    super(controller, layout, model, 'list-item')
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    element.dataset.type = 'item'
  }

  getChildControls () {}
}

export default ListItemControl
