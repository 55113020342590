import GlobalProps from '../controls/handlebars/global-props'
import { getImageUrl, getImageSourceUrl } from '../../shared/image-url-helper'
import { SECTION_STATUS } from 'shared/enums'

const controlTypeSizeMap = {
  default: {
    background: 2000,
    content: 1000,
    'list-item': 600
  },
  defaultPhone: {
    background: 500,
    content: 400,
    'list-item': 200
  },
  thumbnail: {
    background: 400,
    content: 200,
    'list-item': 200
  },
  dynamic: {
    background: 1,
    content: 0.5,
    'list-item': 0.333
  }
}

// https://mediag.com/blog/popular-screen-resolutions-designing-for-all/
const phoneScreenSize = 412

const getPreferredSize = (controlType, sectionType) => {
  const isSectionPicker = sectionType && sectionType === SECTION_STATUS.SECTION_PREVIEW

  let name
  if (sectionType) {
    name = isSectionPicker ? 'thumbnail' : 'default'
  } else {
    name = GlobalProps.isInPageOverviewMode ? 'thumbnail' : 'default'
  }

  // Use smaller images on mobile, to optimize page speed
  if (
    !GlobalProps.inDesigner &&
    name === 'default' &&
    typeof window !== 'undefined' &&
    window.innerWidth <= phoneScreenSize
  ) {
    name = 'defaultPhone'
  }

  return Math.round(controlTypeSizeMap[name][controlType] || 500)
}

const retinaSize = size => Math.round(size * 1.5)

// return background image styling based on preferred size and/or retina
export const pickImage = (image, controlType, sectionType, scale = 1) => {
  const cdnType = GlobalProps.useCloudflareCdn ? 'cloudflareHosted' : 'cloudflare'

  const preferredSize = getPreferredSize(controlType, sectionType) * scale

  const defaultUrl = getImageUrl(image, preferredSize, cdnType)

  if (!defaultUrl) {
    return null
  }

  // const retinaUrl = getImageUrl(image, retinaSize(preferedSize), cdnType)
  // return `background-image: url('${defaultUrl}'); background-image: -webkit-image-set( url('${defaultUrl}') 1x, url('${retinaUrl}') 2x );`
  return `background-image: url('${defaultUrl}');`
}

// return an url based on preferredSize and/or retina.
export const pickUrl = (image, controlType, isRetina, scale = 1) => {
  if (!image) {
    return ''
  }

  if (image.noCdn) {
    return getImageSourceUrl(image)
  }

  const cdnType = GlobalProps.useCloudflareCdn ? 'cloudflareHosted' : 'cloudflare'

  const preferredSize = parseInt(getPreferredSize(controlType) * scale)
  return getImageUrl(image, isRetina ? retinaSize(preferredSize) : preferredSize, cdnType)
}
