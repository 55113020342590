import ColorFunctions from './color-functions'
import StringParser from './string-parser'

const sassFunctions = ['darken', 'lighten', 'rgba', 'rgb'] /*, 'fade-out', 'fade-in' */

// TODO: move to seperate class
class SassFunctionParser {
  static parseFunctions (str) {
    // box-shadow: 0px 2px 4px rgba(rgb(255,255,255), 0.5); todo: werkt niet met
    const parser = new StringParser(str)
    let output = ''
    // console.log('before parseFunctions: ' + str)

    while (!parser.endOfString()) {
      output += parser.getOutput()

      const wordStr = parser.getWord()
      if (wordStr !== '') {
        if (parser.getChar() === '(' && sassFunctions.indexOf(wordStr) !== -1) {
          // Clear function call from output
          parser.getOutput()
          const funcParams = parser.getParams()
          // recursivly parse all parameters for nested functions
          funcParams.map(_ => SassFunctionParser.parseFunctions(_))
          // Call function and add function result to output
          output += SassFunctionParser[wordStr].apply(this, funcParams)
          // Clear params from output
          parser.getOutput()
        }
      } else {
        parser.getChar() // skip a char
      }
    }
    output += parser.getOutput()
    // console.log('after parseFunctions: ' + output)
    return output
  }

  static parse (str, variable, value) {
    // console.log('before replace: ' + str)

    // Replace all variables to the new value
    str = str.replace(new RegExp('\\' + variable, 'g'), value)
    // console.log('after replace: ' + str)

    return SassFunctionParser.parseFunctions(str)
  }

  static rgba (...args) {
    // This could be done much better, but that would require refactoring of more code and change this thing into a full compiler => Object model => execute
    return 'rgba(' + SassFunctionParser.roundRgba(ColorFunctions.strToRgba('rgba(' + args.join(',') + ')')) + ')'
  }

  static rgb (...args) {
    // This could be done much better, but that would require refactoring of more code and change this thing into a full compiler => Object model => execute
    return 'rgba(' + SassFunctionParser.roundRgba(ColorFunctions.strToRgba('rgb(' + args.join(',') + ')')) + ')'
  }

  static darken (...args) {
    if (args.length !== 2) {
      throw new Error('Invalid argument count')
    }

    let rgba = ColorFunctions.strToRgba(args[0].trim())
    const amount = parseFloat(args[1].trim())

    rgba = ColorFunctions.darken(rgba[0], rgba[1], rgba[2], rgba[3], amount)

    return 'rgba(' + SassFunctionParser.roundRgba(rgba).join(',') + ')'
  }

  static lighten (...args) {
    if (args.length !== 2) {
      throw new Error('Invalid argument count')
    }

    let rgba = ColorFunctions.strToRgba(args[0].trim())
    const amount = parseFloat(args[1].trim())

    rgba = ColorFunctions.lighten(rgba[0], rgba[1], rgba[2], rgba[3], amount)

    return 'rgba(' + SassFunctionParser.roundRgba(rgba).join(',') + ')'
  }

  static roundRgba (rgba) {
    return [Math.round(rgba[0]), Math.round(rgba[1]), Math.round(rgba[2]), rgba[3]]
  }

  static roundRgb (rgba) {
    return [Math.round(rgba[0]), Math.round(rgba[1]), Math.round(rgba[2])]
  }
}

export default SassFunctionParser
