import each from 'lodash/each'
import defer from 'lodash/defer'
import isArray from 'lodash/isArray'

import GlobalProps from '../handlebars/global-props'
import LogError from '../../utils/log-error'

////////////////
//////////////////////////////////////////////////////////////
/////////

import SiteRenderer from './mixin/site-renderer'
import BlurInImageMixin from './mixin/blur-in-images-mixin'

import LazyVideosMixin from './mixin/lazy-videos-mixin'
import FitText from './mixin/fit-text-mixin'

const CONTROL_ATTR = 'data-kv-control'

let controlId = 0
let nestingCount = 0

class BaseControl {
  constructor (controller, layout, model, controlType) {
    this.bindLayoutMixins = []
    this.lazyImageSelector = 'img, *[data-src], *[data-image]'
    this.controller = controller
    this.layout = layout
    this.model = model
    this.controlType = controlType
    this.id = controlId++
    this.binding = null

////////////////////
//////////////////////////////////
/////////////

    // this.addMixin(LazeImageMixin)
    this.addMixin(BlurInImageMixin)
    this.addMixin(LazyVideosMixin)
    this.addMixin(SiteRenderer)

    this.addMixin(FitText)

    this.registerView(true)
  }

  addMixin (ClassType) {
    const instance = new ClassType()
    for (const prop in instance) {
      this[prop] = instance[prop]
    }
    Object.getOwnPropertyNames(ClassType.prototype).forEach(funcName => {
      if (funcName !== 'constructor') {
        if (funcName === 'bindLayout') {
          this.bindLayoutMixins.push(ClassType.prototype[funcName])
        } else {
          this[funcName] = ClassType.prototype[funcName] // .bind(this)
        }
      }
    })
  }

  registerView (addOrDeleteBool) {
    this.controller.registerView(this, this.model, addOrDeleteBool)
  }

  requestRender () {
    defer(() => this.render())
  }

  requestRender = () => {
    if (!this.renderPending) {
      this.renderPending = true
      window.requestAnimationFrame(() => {
        if (this.controller.colorModel) {
          this.controller.colorModel.onChangeColorPallet()
        }
        this.render()
      })
    }
  }

  bindLayout (element, data) {
    // Filter out none element type nodes (eg. text, comment, etc)
    if (element.nodeType === window.Node.ELEMENT_NODE) {
      const childElements = element.querySelectorAll(`[${CONTROL_ATTR}].kv-_${this.controlType}`)

      each(childElements, placeholderElement => {
        const controlName = placeholderElement.getAttribute(CONTROL_ATTR)

        let childControl = this.children && this.children[controlName]
        if (!childControl && isNaN(parseInt(controlName))) {
          childControl = this.controller.getControlOfType(controlName)
        }

        if (childControl) {
          const childElement = placeholderElement.nextElementSibling || placeholderElement.nextSibling
          if (childElement) {
            // hook child control that is already rendered from this template
            childControl.setElement(childElement, false, this.binding)
          }
          // remove placeholder
          placeholderElement.parentNode.removeChild(placeholderElement)
        } else {
          placeholderElement.parentNode.removeChild(placeholderElement)
        }
      })
    }

    const hasAnimationsDisabled = this.controller.hasAnimations && !this.controller.hasAnimations()
    const isEditor = !this.controller.isRuntime()
    if (hasAnimationsDisabled || isEditor) {
      // disable animations
      if (element.classList.contains('kv-notify-inview')) {
        element.classList.remove('kv-notify-inview')
      } else {
        const notifyViewElements = element.querySelectorAll('.kv-notify-inview')
        each(notifyViewElements, element => element.classList.remove('kv-notify-inview'))
      }
    }

    if (this.controller.editorContext) {
      element.dataset.isControl = this.controlType
      const elementMap = this.controller.editorContext.elementInstanceMap
      elementMap.set(element, this)
    }

    this.bindLayoutMixins.forEach(func => {
      func.call(this, element, data)
    })
  }

  setElement (newElement, putElementInDom, parentBinding) {
    try {
      this.bindLayout(newElement, this.binding || this.getBinding(parentBinding))
    } catch (ex) {
      LogError.log(`Error in bindLayout ${this.getDesc()}`, ex)
    }

    if (putElementInDom && this.domElement) {
      const parent = this.domElement.parentNode
      if (parent) {
        this.domElement.replaceWith(newElement)
      }
    }

    if (this.domElement && this.domElement.classList.contains('kv-selected')) {
      newElement.classList.add('kv-selected')
    }

    this.domElement = newElement
  }

  getDesc () {
    return `${this.constructor.name} - ${this.controller.constructor.name}`
  }

  getChildControls () {
    try {
      return this.controller.getChildControls()
    } catch (ex) {
      LogError.log(`Error getting child controls in ${this.getDesc()}`, ex)
      return []
    }
  }

  // sometimes the dom is altered not through the render or update method, keep children ref in sync by calling this function
  updateRenderedChildren () {
    this.children = this.getChildControls()
  }

  // rerender the child controls, compare old and new array and only rerender the new items, dispose domelements of old items
  updateChildren () {
    if (!this.domElement) {
      throw new Error('control needs to be rendered before children can be updated')
    }

    const children = this.getChildControls() // note: for array's this is automatically a new instance every time, (due to the ordering with map function)

    const oldItems = this.children
    // check on if children where rendered earlier
    if (isArray(children) && oldItems && oldItems[0] && oldItems[0].domElement) {
      const removedItems = []

      // remove all previous children and add new children again on the same location
      const lastElement = oldItems[oldItems.length - 1].domElement
      let insertBefore = lastElement.nextElementSibling
      let parentElement = lastElement.parentNode
      if (parentElement && parentElement.classList.contains('section-wrapper')) {
        parentElement = parentElement.parentNode
      }

      if (!parentElement) {
        // we can't update if there aren't any items, just render
        this.render()
        return
      }

      oldItems.concat(children).forEach(control => {
        if (control.domElement && control.domElement.parentNode) {
          const isSectionWrapper = control.domElement.parentNode.classList.contains('section-wrapper')

          if (isSectionWrapper) {
            control.domElement.parentNode.remove()
          } else {
            control.domElement.remove()
          }
          if (children.indexOf(control) < 0) {
            removedItems.push(control)
          }
        }
      })

      for (let i = children.length - 1; i >= 0; i--) {
        const newChild = children[i]
        if (!newChild.domElement) {
          newChild.render(this.binding, i)
        }
        const el = newChild.domElement
        if (insertBefore) {
          parentElement.insertBefore(el, insertBefore)
        } else {
          parentElement.appendChild(el)
        }
        insertBefore = el
      }

      // dispose remaining elements
      removedItems.forEach(control => control.disposeElement())
    } else {
      this.render()
    }
    this.children = children
  }

  getBinding (parentBinding) {
    const binding = this.model ? this.controller.getValue(this.model) || {} : {}
    this.binding = binding

    if (parentBinding) {
      binding._parent = parentBinding
    }

    return binding
  }

  renderHtml (parentBinding, index) {
    let html = '<div></div>'
    if (!this.inRenderHtml && nestingCount < 100) {
      this.inRenderHtml = true
      nestingCount++
      try {
        if (parentBinding) {
          // keep on instance for refreshing control
          this._parentBinding = parentBinding
          this._index = index
        }

        const binding = this.getBinding(parentBinding)

        GlobalProps.editable = this.controller.editable()
        GlobalProps.isRuntime = this.controller.isRuntime()

        // child controls
        const children = this.getChildControls()
        this.children = children
        if (children) {
          binding._children = children
        }
        binding._controlType = this.controlType
        binding._getControl = this.controller.getControlOfType
        if (this.injectBinding) {
          this.injectBinding(binding)
        }
        html = this.layout(parentBinding, index, this.model)(binding)

        if (this.controller.onAfterRender) {
          this.controller.onAfterRender()
        }
      } catch (ex) {
        LogError.log(`render error in ${this.getDesc()}`, ex, this.controller.model)
      }
      nestingCount--
      this.inRenderHtml = false
    } else {
      LogError.log(`recursion error in ${this.getDesc()}`, {}, this.controller.model)
    }
    return html // this.domElement = bla
  }

  rerender = () => {
    // only render if we have an element
    if (this.domElement) {
      if (this.canRerender && !this.canRerender(this.domElement)) {
        return
      }

      this.render()
    }
  }

  forEachChildren (callback) {
    if (this.children) {
      each(this.children, callback)
    }
    if (this.controller.subControls) {
      each(this.controller.subControls, callback)
    }
  }

  setChildrenRecursive () {
    this.children = this.getChildControls()
    this.forEachChildren(child => child.setChildrenRecursive())
  }

  getSectionControl () {
    // Because SiteControl does not seem to have a parentController
    // and PageControl never seems to be constructed this piece of
    // code resolves a SectionControl successfully.
    if (this.controller.parentController) {
      return this.controller.parentController.control
    }
  }

  dispose () {
    this.forEachChildren(child => {
      child.controller.dispose()
      child.dispose()
    })
    this.children = null

    this.controller.control = null // remove references
    this.disposeElement()
  }

  disposeElement () {
    if (this.domElement) {
      if (this.domElement.parentElement) {
        this.domElement.parentElement.removeChild(this.domElement) // remove just to be sure
      }
      if (this.controller.editorContext) {
        // check editorContext instead of editable() because we want to know the ref in the page overview as well
        this.controller.editorContext.elementInstanceMap.delete(this.domElement)
      }
      this.domElement = null
    }
    this.forEachChildren(child => child.disposeElement())
  }
}

export default BaseControl
