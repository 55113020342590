import * as constants from '../constants'

export const registerPopoverWindow = payload => {
  return {
    type: constants.REGISTER_POPOVER_WINDOW,
    payload: payload
  }
}

export const activatePopoverWindow = payload => {
  return {
    type: constants.ACTIVATE_POPOVER_WINDOW,
    payload: payload
  }
}

export const deactivatePopoverWindow = payload => {
  return {
    type: constants.DEACTIVATE_POPOVER_WINDOW,
    payload: payload
  }
}

export const updatePopoverWindow = payload => {
  return {
    type: constants.UPDATE_POPOVER_WINDOW,
    payload: payload
  }
}

export const setPopoverWindowOrientation = payload => {
  return {
    type: constants.SET_POPOVER_WINDOW_ORIENTATION,
    payload: payload
  }
}
